import clsx from "clsx";
import dayjs from "dayjs";

import ClipPath from "@/components/elements/ClipPath";
import Heading from "@/components/elements/Heading";
import Image from "@/components/elements/Image";
import LinkComponent from "@/components/elements/LinkComponent";
import Tag from "@/components/elements/Tag";

import useDataSource from "@/base/hooks/useDataSource";
import usePrintPage from "@/base/hooks/usePrintPage";
import Button from "@/components/elements/Button";
import * as styles from "./styles.module.scss";

export function ScientificUpdateHero({
  su_categories,
  su_date_of_publication,
  su_title,
  su_labels,
  su_hero_img,
}: Pick<
  ScientificUpdatePageTemplate,
  | "su_categories"
  | "su_date_of_publication"
  | "su_title"
  | "su_labels"
  | "su_hero_img"
>) {
  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const { printPage } = usePrintPage();

  return (
    <section className={clsx(styles.sectionHero, "container")}>
      <LinkComponent url="/scientific-updates" type="internal">
        <p className={styles.subtitle}>Scientific Updates /</p>
      </LinkComponent>
      <Heading level={1}>{su_title}</Heading>

      <p className={styles.date}>
        {dayjs(su_date_of_publication).format("DD MMMM YYYY")}
      </p>
      <Button
        className={styles.button}
        icon="download"
        variant="text"
        onClick={printPage}
      >
        Save article as PDF
      </Button>

      <div className={styles.tags}>
        {su_labels && su_labels.length > 0 && (
          <div className={styles.types}>
            <p>Type: </p>
            {Array.isArray(su_labels) &&
              su_labels.map((label) => <Tag type={label} key={label} />)}
          </div>
        )}
        {su_categories.length > 0 && (
          <div className={styles.categories}>
            {su_categories.map((category) => (
              <LinkComponent
                key={category}
                url={`/scientific-updates/${category
                  .toLowerCase()
                  .replace(" ", "-")}`}
                type="external"
                className={styles.tag}
              >
                <span>{getNameByValue(category)}</span>
              </LinkComponent>
            ))}
          </div>
        )}
      </div>

      {su_hero_img?.image && (
        <ClipPath>
          <Image fluid data={su_hero_img} alt={su_title} strategy="eager" />
        </ClipPath>
      )}
    </section>
  );
}
