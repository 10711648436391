import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";

import Button from "@/components/elements/Button";
import LinkComponent from "@/components/elements/LinkComponent";

import * as styles from "./styles.module.scss";

type CtaLink = DocumentElement | CtaLinkElement;

interface FloatingBoxProps {
  postUrl: string;
  su_cta?: CtaLink[];
}

export function FloatingBox({ postUrl, su_cta }: FloatingBoxProps) {
  const shareUrl = `${process.env.GATSBY_URL}/${postUrl}`;

  return (
    <div className={styles.floatingContainer}>
      {Array.isArray(su_cta) &&
        su_cta.map((item, i) => (
          <div className={styles.box} key={`${item.title}${i}`}>
            <p>{item.label}</p>
            <p>{item.title}</p>
            <Button variant="primary">
              {item.component === "element_ctaLink" ? (
                <LinkComponent
                  url={item.link[0].url}
                  type={item.link[0].type}
                  with_icon={item.link[0].with_icon}
                >
                  {item.link[0].title}
                </LinkComponent>
              ) : (
                <LinkComponent url={item?.file.filename || "/"} type="external">
                  {item?.download_label}
                </LinkComponent>
              )}
            </Button>
          </div>
        ))}
      <div className={styles.socials}>
        <p>Share this article on social media.</p>
        <div>
          <LinkedinShareButton url={shareUrl}>
            <img src="/svgs/floating-box/linkedin.svg" alt="linkedin" />
          </LinkedinShareButton>
          <FacebookShareButton url={shareUrl}>
            <img src="/svgs/floating-box/fb.svg" alt="facebook" />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl}>
            <img src="/svgs/floating-box/twitter.svg"alt="twitter" />
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
}
