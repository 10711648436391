import clsx from "clsx";
import ClipPath from "@/components/elements/ClipPath";
import FloatingBox from "@/components/elements/FloatingBox";
import Image from "@/components/elements/Image";
import RichText from "@/components/elements/RichText";

import usePrintPage from "@/base/hooks/usePrintPage";
import Button from "@/components/elements/Button";
import * as styles from "./styles.module.scss";

interface ScientificUpdateContentProps
  extends Pick<
    ScientificUpdatePageTemplate,
    "su_content" | "su_cta" | "su_hero_img" | "su_title"
  > {
  postUrl: string;
}

export function ScientificUpdateContent({
  su_content,
  su_cta,
  su_hero_img,
  su_title,
  postUrl,
}: ScientificUpdateContentProps) {
  const { printPage } = usePrintPage();
  return (
    <section className={styles.scientificUpdateContent}>
      <div className={clsx(styles.contentContainer, "container")}>
        <div className={styles.content}>
          {su_hero_img?.image && (
            <ClipPath>
              <Image fluid data={su_hero_img} alt={su_title} />
            </ClipPath>
          )}
          <RichText content={su_content} />
          <div className={styles.save}>
            <h3>Save article as PDF</h3>

            <Button icon="download" variant="outlined" onClick={printPage}>
              Save article as PDF
            </Button>
          </div>
        </div>
        <FloatingBox postUrl={postUrl} su_cta={su_cta} />
      </div>
    </section>
  );
}
